// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-js": () => import("../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-when-js": () => import("../src/pages/when.js" /* webpackChunkName: "component---src-pages-when-js" */),
  "component---src-pages-where-js": () => import("../src/pages/where.js" /* webpackChunkName: "component---src-pages-where-js" */),
  "component---src-pages-who-js": () => import("../src/pages/who.js" /* webpackChunkName: "component---src-pages-who-js" */)
}

